<template>
	<div class="home">
		<div>
			<div style="float: left;margin-bottom: 10px;">
				<el-input size="mini" v-model="this.listQuery.name" style="width: 180px;"  @keyup.enter="getList"></el-input>
				<el-button size="mini" type="primary" @click="getList" style="margin-right:10px">搜索</el-button>
				<!-- <selectTaskCamera v-model:taskNames="listQuery.taskNames" jcType="1"></selectTaskCamera> -->
				<!-- <selectTaskCamera v-model:taskNames="listQuery.taskNames" jcType="2"></selectTaskCamera> -->
				<selectTaskCamera v-model:taskNames="listQuery.taskNames" jcType="4"></selectTaskCamera>
				<!-- <selectTaskCamera v-model:taskNames="listQuery.taskNames" jcType="4"></selectTaskCamera> -->

				<el-select v-model="this.listQuery.status" class="m-2" filterable placeholder="选择任务状态" size="mini" @change="getList"
					clearable style="margin-left: 10px;width: 150px;">
					<el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value" />
				</el-select>
				<el-select v-model="this.listQuery.businessStatus" class="m-2" filterable placeholder="选择任务结果" size="mini"
					@change="getList" clearable style="margin-left: 10px;width: 150px;">
					<el-option v-for="item in businessStatusOptions" :key="item.value" :label="item.label" :value="item.value" />
				</el-select>
				<el-input size="mini" v-model="this.listQuery.log" clearable placeholder="搜索错误原因" style="margin-left:10px;width: 120px;" @keyup.enter="getList" @change="getList"></el-input>

				<el-date-picker v-model="listQuery.period" type="month" format="YYYYMM" value-format="YYYYMM" placeholder="请选择账期" size="mini" style="margin-left: 10px;width: 120px;" @change="getList()" :clearable="false"> </el-date-picker>
				<selectAddress v-model:address="listQuery.address" @success="getList()" style="margin-left:10px"></selectAddress>

				<el-date-picker
					v-model="this.timetime"
					type="date"
					size="mini"
					placeholder="选择日期"
					style="width:130px;margin-left: 10px;"
					@change="changeDateTime"
					value-format="YYYY-MM-DD HH:mm:ss">
				</el-date-picker>

				<!-- <el-date-picker
					size="mini"
					v-model="listQuery.region"
					type="datetimerange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					style="width:312px;margin-left: 10px;"
					@change="getList()"
					value-format="YYYY-MM-DD HH:mm:ss">
				</el-date-picker> -->

			</div>
			<div style="text-align: right;margin-bottom: 10px;">
				<el-button size="mini" type="success" @click="handleUpdateAll">重新发起</el-button>
			</div>
		</div>

		<el-table size="mini" v-loading="isLoading" :data="tableData" :height="contentStyleObj" border style="width: 100%"
			:header-cell-style="{background:'#66b1ff',color:'#ffff'}" @selection-change="handleSelectionChange">
			<el-table-column type="selection" width="55" align="center" />
			<el-table-column fixed type="index" width="50" label="序号" align="center"  >
			</el-table-column>
			<el-table-column label="机构名称" align="center" min-width="200px">
				<template #default="scope">
					<span>{{ scope.row.alias }}</span>
				</template>
			</el-table-column>
			<el-table-column label="企业名称" align="center" min-width="200px">
				<template #default="scope">
					<span>{{ scope.row.comName }}</span>
				</template>
			</el-table-column>
			<el-table-column label="税号" align="center" min-width="160px">
				<template #default="scope">
					{{scope.row.tax_no}}
				</template>
			</el-table-column>
			<el-table-column label="账期" align="center" min-width="80px">
				<template #default="scope">
					{{scope.row.period}}
				</template>
			</el-table-column>
			<el-table-column label="任务类型" align="center" min-width="160px">
				<template #default="scope">
					{{ $taskNameType(scope.row.taskName)}}
				</template>
			</el-table-column>
			<!-- <el-table-column label="任务类别" align="center">
				<template #default="scope">
					{{ scope.row.fromType}}
				</template>
			</el-table-column> -->
			<el-table-column label="任务状态" align="center" min-width="110px">
				<template #default="scope">
					<div class="item_icon">
						<!-- 排队中 0-->
						<p v-if="scope.row.status == '0'">
							<i class="el-icon-info"></i>
							<el-tooltip v-if="scope.row.errLog" class="item" effect="dark" :content="scope.row.errLog"
								placement="top-start">
								<el-button size="mini" class="sbcg" style="border: 0;">{{filterStatus(scope.row.status)}}</el-button>
							</el-tooltip>
							<span v-else>
								<el-button size="mini" class="sbcg" style="border: 0;">{{filterStatus(scope.row.status)}}</el-button>
							</span>
						</p>

						<!-- 申报中 1 -->
						<p v-if="scope.row.status == '1'">
							<i class="el-icon-info"></i>
							<el-tooltip v-if="scope.row.errLog" class="item" effect="dark" :content="scope.row.errLog"
								placement="top-start">
								<el-button size="mini" class="sbcg" style="border: 0;">{{filterStatus(scope.row.status)}}</el-button>
							</el-tooltip>
							<span v-else>
								<el-button size="mini" class="sbcg" style="border: 0;">{{filterStatus(scope.row.status)}}</el-button>
							</span>
						</p>

						<!-- 成功 2-->
						<p v-if="scope.row.status == '2'">
							<i class="el-icon-success"></i>
							<el-tooltip v-if="scope.row.errLog" class="item" effect="dark" :content="scope.row.errLog"
								placement="top-start">
								<el-button size="mini" class="sbcg" style="border: 0;">{{filterStatus(scope.row.status)}}</el-button>
							</el-tooltip>
							<span v-else>
								<el-button size="mini" class="sbcg" style="border: 0;">{{filterStatus(scope.row.status)}}</el-button>
							</span>
						</p>

						<!-- 失败 3 -->
						<p v-if="scope.row.status == '3'">
							<i class="el-icon-error"></i>
							<el-tooltip v-if="scope.row.errLog" class="item" effect="dark" :content="scope.row.errLog"
								placement="top-start">
								<el-button size="mini" class="sbcg" style="border: 0;">{{filterStatus(scope.row.status)}}</el-button>
							</el-tooltip>
							<span v-else>
								<el-button size="mini" class="sbcg" style="border: 0;">{{filterStatus(scope.row.status)}}</el-button>
							</span>
						</p>

						<p v-if="scope.row.status == '6'">
							<i class="el-icon-error"></i>
							<el-tooltip v-if="scope.row.errLog" class="item" effect="dark" :content="scope.row.errLog"
								placement="top-start">
								<el-button size="mini" class="sbcg" style="border: 0;">{{filterStatus(scope.row.status)}}</el-button>
							</el-tooltip>
							<span v-else>
								<el-button size="mini" class="sbcg" style="border: 0;">{{filterStatus(scope.row.status)}}</el-button>
							</span>
						</p>
					</div>
				</template>
			</el-table-column>

			<el-table-column label="任务结果" align="left" min-width="280px">
				<template #default="scope">
					<div v-if="scope.row.status == '3' || scope.row.status == '2'">
						<span v-if="scope.row.businessImg" class="demo-image__preview">
							<i class="el-icon-picture" v-if="scope.row.status == '3'" style="color:#f56c6c;font-size:16px;"
								@click="open(scope.row, scope.$index)"></i>
							<i class="el-icon-picture" v-if="scope.row.status == '2'" style="color:#67c23a;font-size:16px;"
								@click="open(scope.row, scope.$index)"></i>
						</span>

						<div class="item_icon">
							<!-- 申报成功,无需扣款 -->
							<p v-if="scope.row.businessStatus == '2'" class="overflowEll">
								<i class="el-icon-success"></i>

								<el-tooltip class="item" effect="dark" :content="scope.row.businessLog" :disabled="!scope.row.businessLog" placement="top-start">
									<el-button size="mini" class="sbcg" style="border: 0;">{{$batchSendSb(scope.row.businessStatus)}}
									</el-button>
								</el-tooltip>
								<span style="color:red" v-if="scope.row.businessLog">({{scope.row.businessLog}})</span>

							</p>

							<!-- 申报失败 -->
							<p v-if="scope.row.businessStatus == '3'" class="overflowEll">
								<i class="el-icon-error"></i>

								<el-tooltip class="item" effect="dark" :content="scope.row.businessLog" :disabled="!scope.row.businessLog" placement="top-start">
									<el-button size="mini" class="sbcg" style="border: 0;color:red">{{$batchSendSb(scope.row.businessStatus)}}
									</el-button>
								</el-tooltip>
								<span style="color:red" v-if="scope.row.businessLog">({{scope.row.businessLog}})</span>

							</p>

							<!-- 提交金三处理中 -->
							<p v-if="scope.row.businessStatus == '4'" class="overflowEll">
								<i class="el-icon-info"></i>

								<el-tooltip class="item" effect="dark" :content="scope.row.businessLog" :disabled="!scope.row.businessLog" placement="top-start">
									<el-button size="mini" class="sbyc" style="border: 0;">{{$batchSendSb(scope.row.businessStatus)}}
									</el-button>
								</el-tooltip>
								<span style="color:red" v-if="scope.row.businessLog">({{scope.row.businessLog}})</span>

							</p>

							<!-- 申报成功,待缴款 -->
							<p v-if="scope.row.businessStatus == '5'" class="overflowEll">
								<i class="el-icon-success"></i>

								<el-tooltip class="item" effect="dark" :content="scope.row.businessLog" :disabled="!scope.row.businessLog" placement="top-start">
									<el-button size="mini" class="sbyc" style="border: 0;">{{$batchSendSb(scope.row.businessStatus)}}
									</el-button>
								</el-tooltip>
								<span style="color:red" v-if="scope.row.businessLog">({{scope.row.businessLog}})</span>

							</p>

							<!-- 申报成功,已缴款 -->
							<p v-if="scope.row.businessStatus == '6'" class="overflowEll">
								<i class="el-icon-success"></i>

								<el-tooltip class="item" effect="dark" :content="scope.row.businessLog" :disabled="!scope.row.businessLog" placement="top-start">
									<el-button size="mini" class="sbyc" style="border: 0;">{{$batchSendSb(scope.row.businessStatus)}}
									</el-button>
								</el-tooltip>
								<span style="color:red" v-if="scope.row.businessLog">({{scope.row.businessLog}})</span>

							</p>

							<!-- 已申报过,无需扣款 -->
							<p v-if="scope.row.businessStatus == '20'" class="overflowEll">
								<i class="el-icon-success"></i>
								<el-tooltip class="item" effect="dark" :content="scope.row.businessLog" :disabled="!scope.row.businessLog" placement="top-start">
									<el-button size="mini" class="sbcg" style="border: 0;">{{$batchSendSb(scope.row.businessStatus)}}
									</el-button>
								</el-tooltip>
								<span style="color:red" v-if="scope.row.businessLog">({{scope.row.businessLog}})</span>
							</p>

							<!-- 已申报过,待缴款 -->
							<p v-if="scope.row.businessStatus == '21'" class="overflowEll">
								<i class="el-icon-success"></i>
								<el-tooltip class="item" effect="dark" :content="scope.row.businessLog" :disabled="!scope.row.businessLog" placement="top-start">
									<el-button size="mini" class="sbyc" style="border: 0;">{{$batchSendSb(scope.row.businessStatus)}}
									</el-button>
								</el-tooltip>
								<span style="color:red" v-if="scope.row.businessLog">({{scope.row.businessLog}})</span>
							</p>

							<!-- 已申报过,已缴款 -->
							<p v-if="scope.row.businessStatus == '22'" class="overflowEll">
								<i class="el-icon-success"></i>
								<el-tooltip class="item" effect="dark" :content="scope.row.businessLog" :disabled="!scope.row.businessLog" placement="top-start">
									<el-button size="mini" class="sbyc" style="border: 0;">{{$batchSendSb(scope.row.businessStatus)}}
									</el-button>
								</el-tooltip>
								<span style="color:red" v-if="scope.row.businessLog">({{scope.row.businessLog}})</span>
							</p>

						</div>
					</div>
				</template>
			</el-table-column>
			<!-- <el-table-column label="标识符" align="center">
				<template #default="scope">
					{{scope.row.reqNo}}
				</template>
			</el-table-column>
			<el-table-column min-width="115px" label="返回文件地址" align="center" >
				<template #default="scope">
					<el-tooltip v-if="scope.row.dataPath" class="item" effect="dark" :content="scope.row.dataPath"
						placement="top-start">
						<el-button size="mini" type="primary" plain @click="checkPath(scope.row.dataPath)">查看信息</el-button>
					</el-tooltip>
				</template>
			</el-table-column>
			<el-table-column label="请求文件地址" align="center" min-width="115px">
				<template #default="scope">
					<el-tooltip v-if="scope.row.data" class="item" effect="dark" :content="scope.row.data"
						placement="top-start">
						<el-button size="mini" type="primary" plain @click="checkPath(scope.row.data)">查看信息</el-button>
					</el-tooltip>
				</template>
			</el-table-column>
			<el-table-column label="回调地址" align="center">
				<template #default="scope">
					{{scope.row.notifyUrl}}
				</template>
			</el-table-column> -->
			<el-table-column label="地区" align="center" min-width="90px">
				<template #default="scope">
					{{ $cityFilter(scope.row.address)}}

				</template>
			</el-table-column>
			<el-table-column label="更新时间" align="center" min-width="115px">
				<template #default="scope">
					{{ $parseTime(scope.row.updatedAt,'{y}-{m}-{d} {h}:{i}')}}
				</template>
			</el-table-column>
			<el-table-column label="操作" align="center" min-width="100px">
				<template #default="scope">

					<!-- <el-popconfirm title="确认再次回调吗" @confirm="handleCallBack(scope.row.reqNo)">
						<template #reference>
							<el-button size="mini" type="primary">再次回调</el-button>
						</template>
					</el-popconfirm> -->
					<el-button size="mini" type="primary" @click="checkDetail(scope.row.id)">查看详情</el-button>


				</template>
			</el-table-column>
		</el-table>
		<sbImgUrl ref="sbImgUrl"></sbImgUrl>


		<!-- 导入 -->
	</div>
	<div class="pagination">
		<qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit"
			@pagination="getList" />
	</div>

	<el-dialog title="查看详情" v-model="dialogTableVisible">
		<el-table size="mini" v-loading="isLoading" :data="gridData" :height="contentStyleObj" border style="width: 100%"
			:header-cell-style="{background:'#66b1ff',color:'#ffff'}" @selection-change="handleSelectionChange">

			<!-- <el-table-column label="机构名称" align="center">
				<template #default="scope">
					<span>{{ scope.row.alias }}</span>
				</template>
			</el-table-column> -->
			<el-table-column label="企业名称" align="center" min-width="200px">
				<template #default="scope">
					<span>{{ scope.row.comName }}</span>
				</template>
			</el-table-column>
			<el-table-column label="税号" align="center" min-width="160px">
				<template #default="scope">
					{{scope.row.tax_no}}
				</template>
			</el-table-column>
			<el-table-column label="账期" align="center" min-width="80px">
				<template #default="scope">
					{{scope.row.period}}
				</template>
			</el-table-column>
			<el-table-column label="任务类型" align="center">
				<template #default="scope">
					{{ $taskNameType(scope.row.taskName)}}
				</template>
			</el-table-column>
			<!-- <el-table-column label="任务类别" align="center">
				<template #default="scope">
					{{ scope.row.fromType}}
				</template>
			</el-table-column> -->
			<el-table-column label="任务状态" align="center" min-width="110px">
				<template #default="scope">
					<div class="item_icon">
						<!-- 排队中 0-->
						<p v-if="scope.row.status == '0'">
							<i class="el-icon-info"></i>
							<el-tooltip v-if="scope.row.errLog" class="item" effect="dark" :content="scope.row.errLog"
								placement="top-start">
								<el-button size="mini" class="sbcg" style="border: 0;">{{filterStatus(scope.row.status)}}</el-button>
							</el-tooltip>
							<span v-else>
								<el-button size="mini" class="sbcg" style="border: 0;">{{filterStatus(scope.row.status)}}</el-button>
							</span>
						</p>

						<!-- 申报中 1 -->
						<p v-if="scope.row.status == '1'">
							<i class="el-icon-info"></i>
							<el-tooltip v-if="scope.row.errLog" class="item" effect="dark" :content="scope.row.errLog"
								placement="top-start">
								<el-button size="mini" class="sbcg" style="border: 0;">{{filterStatus(scope.row.status)}}</el-button>
							</el-tooltip>
							<span v-else>
								<el-button size="mini" class="sbcg" style="border: 0;">{{filterStatus(scope.row.status)}}</el-button>
							</span>
						</p>

						<!-- 成功 2-->
						<p v-if="scope.row.status == '2'">
							<i class="el-icon-success"></i>
							<el-tooltip v-if="scope.row.errLog" class="item" effect="dark" :content="scope.row.errLog"
								placement="top-start">
								<el-button size="mini" class="sbcg" style="border: 0;">{{filterStatus(scope.row.status)}}</el-button>
							</el-tooltip>
							<span v-else>
								<el-button size="mini" class="sbcg" style="border: 0;">{{filterStatus(scope.row.status)}}</el-button>
							</span>
						</p>

						<!-- 失败 3 -->
						<p v-if="scope.row.status == '3'">
							<i class="el-icon-error"></i>
							<el-tooltip v-if="scope.row.errLog" class="item" effect="dark" :content="scope.row.errLog"
								placement="top-start">
								<el-button size="mini" class="sbcg" style="border: 0;">{{filterStatus(scope.row.status)}}</el-button>
							</el-tooltip>
							<span v-else>
								<el-button size="mini" class="sbcg" style="border: 0;">{{filterStatus(scope.row.status)}}</el-button>
							</span>
						</p>

						<p v-if="scope.row.status == '6'">
							<i class="el-icon-error"></i>
							<el-tooltip v-if="scope.row.errLog" class="item" effect="dark" :content="scope.row.errLog"
								placement="top-start">
								<el-button size="mini" class="sbcg" style="border: 0;">{{filterStatus(scope.row.status)}}</el-button>
							</el-tooltip>
							<span v-else>
								<el-button size="mini" class="sbcg" style="border: 0;">{{filterStatus(scope.row.status)}}</el-button>
							</span>
						</p>
					</div>
				</template>
			</el-table-column>

			<el-table-column label="任务结果" align="left" min-width="280px">
				<template #default="scope">
					<div v-if="scope.row.status == '3' || scope.row.status == '2'">
						<span v-if="scope.row.businessImg" class="demo-image__preview">
							<i class="el-icon-picture" v-if="scope.row.status == '3'" style="color:#f56c6c;font-size:16px;"
								@click="open(scope.row, scope.$index)"></i>
							<i class="el-icon-picture" v-if="scope.row.status == '2'" style="color:#67c23a;font-size:16px;"
								@click="open(scope.row, scope.$index)"></i>
						</span>

						<div class="item_icon">
							<!-- 申报成功,无需扣款 -->
							<p v-if="scope.row.businessStatus == '2'" class="overflowEll">
								<i class="el-icon-success"></i>

								<el-tooltip class="item" effect="dark" :content="scope.row.businessLog" :disabled="!scope.row.businessLog" placement="top-start">
									<el-button size="mini" class="sbcg" style="border: 0;">{{$batchSendSb(scope.row.businessStatus)}}
									</el-button>
								</el-tooltip>
								<span style="color:red" v-if="scope.row.businessLog">({{scope.row.businessLog}})</span>

							</p>

							<!-- 申报失败 -->
							<p v-if="scope.row.businessStatus == '3'" class="overflowEll">
								<i class="el-icon-error"></i>

								<el-tooltip class="item" effect="dark" :content="scope.row.businessLog" :disabled="!scope.row.businessLog" placement="top-start">
									<el-button size="mini" class="sbcg" style="border: 0;color:red">{{$batchSendSb(scope.row.businessStatus)}}
									</el-button>
								</el-tooltip>
								<span style="color:red" v-if="scope.row.businessLog">({{scope.row.businessLog}})</span>

							</p>

							<!-- 提交金三处理中 -->
							<p v-if="scope.row.businessStatus == '4'" class="overflowEll">
								<i class="el-icon-info"></i>

								<el-tooltip class="item" effect="dark" :content="scope.row.businessLog" :disabled="!scope.row.businessLog" placement="top-start">
									<el-button size="mini" class="sbyc" style="border: 0;">{{$batchSendSb(scope.row.businessStatus)}}
									</el-button>
								</el-tooltip>
								<span style="color:red" v-if="scope.row.businessLog">({{scope.row.businessLog}})</span>

							</p>

							<!-- 申报成功,待缴款 -->
							<p v-if="scope.row.businessStatus == '5'" class="overflowEll">
								<i class="el-icon-success"></i>

								<el-tooltip class="item" effect="dark" :content="scope.row.businessLog" :disabled="!scope.row.businessLog" placement="top-start">
									<el-button size="mini" class="sbyc" style="border: 0;">{{$batchSendSb(scope.row.businessStatus)}}
									</el-button>
								</el-tooltip>
								<span style="color:red" v-if="scope.row.businessLog">({{scope.row.businessLog}})</span>

							</p>

							<!-- 申报成功,已缴款 -->
							<p v-if="scope.row.businessStatus == '6'" class="overflowEll">
								<i class="el-icon-success"></i>

								<el-tooltip class="item" effect="dark" :content="scope.row.businessLog" :disabled="!scope.row.businessLog" placement="top-start">
									<el-button size="mini" class="sbyc" style="border: 0;">{{$batchSendSb(scope.row.businessStatus)}}
									</el-button>
								</el-tooltip>
								<span style="color:red" v-if="scope.row.businessLog">({{scope.row.businessLog}})</span>

							</p>

							<!-- 已申报过,无需扣款 -->
							<p v-if="scope.row.businessStatus == '20'" class="overflowEll">
								<i class="el-icon-success"></i>
								<el-tooltip class="item" effect="dark" :content="scope.row.businessLog" :disabled="!scope.row.businessLog" placement="top-start">
									<el-button size="mini" class="sbcg" style="border: 0;">{{$batchSendSb(scope.row.businessStatus)}}
									</el-button>
								</el-tooltip>
								<span style="color:red" v-if="scope.row.businessLog">({{scope.row.businessLog}})</span>
							</p>

							<!-- 已申报过,待缴款 -->
							<p v-if="scope.row.businessStatus == '21'" class="overflowEll">
								<i class="el-icon-success"></i>
								<el-tooltip class="item" effect="dark" :content="scope.row.businessLog" :disabled="!scope.row.businessLog" placement="top-start">
									<el-button size="mini" class="sbyc" style="border: 0;">{{$batchSendSb(scope.row.businessStatus)}}
									</el-button>
								</el-tooltip>
								<span style="color:red" v-if="scope.row.businessLog">({{scope.row.businessLog}})</span>
							</p>

							<!-- 已申报过,已缴款 -->
							<p v-if="scope.row.businessStatus == '22'" class="overflowEll">
								<i class="el-icon-success"></i>
								<el-tooltip class="item" effect="dark" :content="scope.row.businessLog" :disabled="!scope.row.businessLog" placement="top-start">
									<el-button size="mini" class="sbyc" style="border: 0;">{{$batchSendSb(scope.row.businessStatus)}}
									</el-button>
								</el-tooltip>
								<span style="color:red" v-if="scope.row.businessLog">({{scope.row.businessLog}})</span>
							</p>

						</div>
					</div>
				</template>
			</el-table-column>
			<!-- <el-table-column label="标识符" align="center">
				<template #default="scope">
					{{scope.row.reqNo}}
				</template>
			</el-table-column>
			<el-table-column min-width="115px" label="返回文件地址" align="center" >
				<template #default="scope">
					<el-tooltip v-if="scope.row.dataPath" class="item" effect="dark" :content="scope.row.dataPath"
						placement="top-start">
						<el-button size="mini" type="primary" plain @click="checkPath(scope.row.dataPath)">查看信息</el-button>
					</el-tooltip>
				</template>
			</el-table-column>
			<el-table-column label="请求文件地址" align="center" min-width="115px">
				<template #default="scope">
					<el-tooltip v-if="scope.row.data" class="item" effect="dark" :content="scope.row.data"
						placement="top-start">
						<el-button size="mini" type="primary" plain @click="checkPath(scope.row.data)">查看信息</el-button>
					</el-tooltip>
				</template>
			</el-table-column>
			<el-table-column label="回调地址" align="center">
				<template #default="scope">
					{{scope.row.notifyUrl}}
				</template>
			</el-table-column> -->
			<el-table-column label="地区" align="center">
				<template #default="scope">
					{{ $cityFilter(scope.row.address)}}
					
				</template>
			</el-table-column>
			<el-table-column label="更新时间" align="center" min-width="115px">
				<template #default="scope">
					{{ $parseTime(scope.row.updatedAt,'{y}-{m}-{d} {h}:{i}')}}
				</template>
			</el-table-column>
			<el-table-column label="操作" align="center" min-width="110px">
				<template #default="scope">
					<el-button size="mini" type="primary" @click="checkDetail(scope.row.id)">查看详情</el-button>


				</template>
			</el-table-column>
		</el-table>
	</el-dialog>
</template>

<script>
import { taxTaskList, againTaxCallback,readFile, taxTaskAgain,getTaskDetail ,getTaxList} from "@/api/taxBureau";
import sbImgUrl from "./components/sbImgUrl.vue"
import selectAddress from '@/components/select/selectAddress';
import selectTaskCamera from "./components/selectTaskCamera.vue"


export default {
	name:'taskCameraBur',
	components: {
		sbImgUrl,
		selectAddress,
		selectTaskCamera
	},
	data() {
		return {
			listQuery: {
				page: 1,
				limit: 20,
				// taskNames:["other-cj-jianzhang"],
				// taskNames:["tax-cj-outInvoice",'tax-cj-inInvoice'],
				taskNames:['tax-jc-shenbao','tax-jc-shebao'],
				period: this.$currentAccountPeriod(),
				region:[]
			},
			timetime:undefined,

			contentStyleObj: {},
			statusOptions: [
        {
          value: '0',
          label: '排队中',
        },
        {
          value: '1',
          label: '检查中',
        },
        {
          value: '2',
          label: '成功',
        },
        {
          value: '3',
          label: '失败',
        },
      ],
			businessStatusOptions: [
				{
          value:'2',
					label:'申报成功无需扣款'
				},
				{
					value:'3',
					label:'申报失败'
				},
				{
					value:'4',
					label:'提交金三处理中'
				},
				{
					value:'5',
					label:'申报成功,待缴款'
				},
				{
					value:'6',
					label:'申报成功,已缴款'
				},
				{
					value:'20',
					label:'已申报过，无需扣款'
				},
				{
					value:'21',
					label:'己申报过，待缴款'
				},
				{
					value:'22',
					label:'已申报过，已缴款'
				}
			],
			total: 0,
			tableData: [],
			gridData:[],
			multipleSelection: [],
			dialogVisible: false,
			dialogVisibleUpdate: false,
			dialogTableVisible:false,
			dialogFormVisibleImport: false,
			visible: false,
			isLoading: false,
			form: {
				name: '',
				loginType: '',
				tax_no: '',
				idNo: '',
				password: '',
				address: ''
			},
			updateForm: {
				name: '',
				loginType: '',
				tax_no: '',
				idNo: '',
				password: '',
				address: ''
			},
			value: [],
			options: [],
			loginTypeOptions: [
				{
					name: '申报密码登录',
				},
				{
					name: '实名信息登录',
				},
			],
			path: '',
			rules: {
				name: [
					{ required: true, message: '请输入企业名称', trigger: 'blur' },
				],
				loginType: [
					{ required: true, message: '请输入登录方式', trigger: 'blur' },
				],
				tax_no: [
					{ required: true, message: '请输入纳税人识别号', trigger: 'blur' },
				],
				idNo: [
					{ required: true, message: '请输入身份证号', trigger: 'blur' },
				],
				password: [
					{ required: true, message: '请输入密码', trigger: 'blur' },
				],
				address: [
					{ required: true, message: '请选择地区', trigger: 'blur' },
				],
			}
		}
	},
	created() {
		this.getList()
		// this.init()
		this.contentStyleObj = this.$getHeight(280)
	},
	methods: {
		filterTaskName(taskName) {
			var info = this.taskNameTypeOptions.filter(v => {
				if (taskName == v.value) {
					return v
				}
			})
			if (info.length == 1) {
				return info[0].label
			}
			return ""
		},
		// 获取list
		getList() {
			this.isLoading = true
			getTaxList(this.listQuery).then(res => {
				this.isLoading = false
				this.total = res.data.data.total
				if (res.data.data.list) {
					this.tableData = res.data.data.list
				} else {
					this.tableData = []
				}

			})
		},
		init() {
		},
		// 过滤
		filterStatus(status) {
			if (status == 0) {
				return '排队中'
			} else if (status == 1) {
				return '申报中'
			} else if (status == 2) {
				return '成功'
			} else if (status == 3) {
				return '失败'
			} else if (status == 6) {
				return '等待回调'
			}
		},
		setUpStatusGsSb(type) {
			if (type == '0') {
				return '未申报'
			} else if (type == '1') {
				return '排队中'
			} else if (type == '2') {
				return '申报中'
			} else if (type == '3') {
				return '申报超时失败'
			} else if (type == '4') {
				return '客户端未启动失败'
			} else if (type == '5') {
				return '申报异常'
			} else if (type == '6') {
				return '申报失败'
			} else if (type == '7') {
				return '申报成功，待缴款'
			} else if (type == '8') {
				return '自动检查中'
			} else if (type == '10') {
				return '申报成功，无需缴款'
			} else if (type == '11') {
				return '扣款中'
			} else if (type == '12') {
				return '扣款异常'
			} else if (type == '13') {
				return '申报成功，扣款成功'
			} else if (type == '14') {
				return '已申报过'
			}
		},

		// 图
		open(row, index) {
			let imageAll = []
			this.tableData.map(res => {
				let url = {
					name: res.name,
					url: ""
				}
				url.url = res.businessImg ? 'https://file.listensoft.net/' + res.businessImg + "?" + Math.random(10) : ""
				console.log(url);
				imageAll.push(url)
			})
			this.$refs.sbImgUrl.dialog(imageAll, index)
		},
		// 选中条数 
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		//  重新发起
		handleUpdate(row) {
			taskAgain([row]).then(res => {
				if (res.data.msg == "success") {
					this.$message({
						message: '已重新发起',
						type: 'success'
					});
					this.getList()
				}
			})
		},
		// 批量重新发起
		// 批量重新发起
		handleUpdateAll() {
			if (this.multipleSelection.length == 0) {
				this.$message({
					message: "请选择",
					type: "warning"
				});
				return false;
			}
			this.$confirm('是否重新发起, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				console.log(this.multipleSelection)
				taxTaskAgain(this.multipleSelection).then(res => {
					if (res.data.msg == "success") {
						this.$message({
							message: '已重新发起',
							type: 'success'
						});
						this.getList()
					}
				})
			})
		},
		// 再次回调
		handleCallBack(reqNo) {
			againTaxCallback({ reqNo: reqNo }).then(res => {
				if (res.data.msg == "success") {
					this.$message({
						message: '发送成功',
						type: 'success'
					});
					this.getList()
				}
			})
		},

		// 查看信息
		checkPath(path){
      readFile({path:path}).then(res => {
				console.log(res.data.data.info);
				let message = res.data.data.info
				if(res.data.msg == "success"){
					this.$alert(
						message,{
							confirmButtonText: '复制',
						}
					).then(()=> {
						this.copyMessage(message)
					});
				}
			})
		},
		checkDetail(id){
			this.dialogTableVisible = true
      getTaskDetail({id:id}).then(res => {
				if(res.data.msg == "success"){
					this.gridData = res.data.data.list
				}
			})
		},
		copyMessage(value){
			const newObj =  JSON.stringify( value )  
			console.log(newObj);
			//创建input标签
      var input = document.createElement('input')
      //将input的值设置为需要复制的内容
      input.value = newObj;
      //添加input标签
      document.body.appendChild(input)
      //选中input标签
      input.select()
      //执行复制
      document.execCommand('copy')
      //成功提示信息
      this.$message.success('复制成功！')
      //移除input标签
      document.body.removeChild(input) 
		},

		// 选择地区
		handleChange(value) {
			console.log(value);
		},
		// 选择时间
		changeaaa(val){
			let date = new Date(val);
			date.setDate(date.getDate() + 1); //num正数为后一天，负数为前一天
			return date
		},
		changeDateTime(val){
			if(val){
				// 先把先前值拿下来  
				this.timetime = val 
				let timetimes =  this.changeaaa(val)
				let arrLast = this.$parseTime(timetimes,'{y}-{m}-{d} {h}:{i}:{s}')
				this.listQuery.region = [this.timetime,arrLast]
			}else{
				this.listQuery.region = []
			}
			this.getList()
		}
	},
	activated() {

	},
}
</script>
<style lang="scss">
.shubiao {
	cursor: pointer;
}
</style>
<style lang="scss" scoped>
.el-table {
	   th {
		padding: 0px;
	}

	   td {
		padding: 0px;
	}
}

.home {
	padding: 10px;

	.header {
		margin-bottom: 10px;

		.right {
			float: right;
		}

		.input-search {
			width: 180px;
			margin-right: 5px;
		}
	}

	.img-ewm {
		width: 50px;
	}
}

.pagination {
	text-align: right;
	margin-right: 26px;
	margin-top: 16px;
}

.el-input {
	width: 80%;
}

.item_icon {
	display: inline-block;
	cursor: pointer;

	i {
		display: inline-block;
		line-height: 28px;
		font-size: 16px;
	}

	.el-icon-error {
		color: #f56c6c;
	}

	.el-icon-success {
		color: #67c23a;
	}

	.el-icon-circle-check {
		color: #67c23a;
	}

	.el-icon-info {
		color: #409eff
	}

	.el-icon-warning {
		color: #e6a23c;
	}

	.el-icon-warning-outline {
		color: #e6a23c;
	}

	p {
		display: inline-block;
		font-size: 14px;
		line-height: 28px;
		color: #333;
	}
}

</style>
<style>
.el-message-box {
	width: 60% !important;
}
</style>